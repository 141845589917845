import React, { useState } from "react";
import { RegisterBuyerUser } from "../../redux/Slice/userAuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";
import "./register.css";
import { Close, Upload } from "@mui/icons-material";
import {
  countryOptions,
  countryOptionss,
  cityOptionsByCountry,
} from "./Country";
import { useTranslation } from "@psyycker/react-translation";
import { useEffect } from "react";
import Select from "react-select";

function RawstuffRegister() {
  const initialState = {
    userFullName: "",
    userEmail: "",
    userphoneNum: "",
    pwd: "",
    pwdconfirm: "",
    Postalcode: "",
    CompanyName: "",
    Street: "",
    Housenumber: "",
    vatNum: "",
    vatNumverify: "",
    Promocode: "",
    isLoading: false,
    authorizedRepPosition: "",
    authorizedRepFirstName: "",
    authorizedRepLastName: "",
    authorizedRepEmail: "",
    authorizedRepTelephone: "",
    authorizedRepDob: "",
    authorizedRepGender: "",
    authorizedRepID: "",
    howDidYouFindOut: "",
    selectSocialMedia: [],
    role: "",
    verificationDocument: "",
    companyDocument: "",
    country: "DE",
    city: "Berlin",
    ImnotRobot: [],
    companyInfo: "",
    country_of_trade: [],
  };

  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordsMatch = formData.pwd === formData.pwdconfirm;
  const passwordErrors =
    submitted && !passwordsMatch ? "Passwörter stimmen nicht überein." : "";
  const vatMatch = formData.vatNum === !formData.vatNumverify;
  const vatNumberMatchError =
    submitted && !vatMatch ? "Die Umsatzsteuerprüfnummer stimmt nicht mit der Umsatzsteuernummer überein." : "";
  const [selectedFirstFile, setSelectedFirstFile] = useState(null);
  const [selectedSecondFile, setSelectedSecondFile] = useState(null);

  const [errorFirstFile, setErrorFirstFile] = useState(null);
  const [errorSecondFile, setErrorSecondFile] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetForm = () => {
    setFormData(initialState);
  };
  const handleChangeRole = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedRole(e.target.value);
    if (type === "checkbox") {
      const isChecked = checked;
      if (isChecked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: [...prevFormData[name], value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: prevFormData[name].filter((item) => item !== value),
        }));
      }
    }
  };

  const handleChangeSocialMedia = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedSocialMedia(e.target.value);
    if (type === "checkbox") {
      const isChecked = checked;
      if (isChecked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: [...prevFormData[name], value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: prevFormData[name].filter((item) => item !== value),
        }));
      }
    }
  };

  const [callbackHandled, setCallbackHandled] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("ref");

    // Update the form data with the referral code
    setFormData((prevData) => ({
      ...prevData,
      Promocode: referralCode || "",
    }));

    // Store referral code in local storage
    localStorage.setItem("referralCode", referralCode || "");

    // Set callbackHandled to true to ensure this logic runs only once
    setCallbackHandled(true);
  }, [location]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      const isChecked = checked;
      if (isChecked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: [...prevFormData[name], value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: prevFormData[name].filter((item) => item !== value),
        }));
      }
    } else if (type === "password") {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      if (name === "pwd" && value.length < 8) {
        setPasswordError("Das Passwort muss mindestens 8 Zeichen lang sein.");
      } else {
        setPasswordError("");
      }
    } else if (type === "file") {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const selectedFile = fileInput.files;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: selectedFile,
        }));
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      if (name === "country") {
        const selectedCountry = value;
        setFormData((prevFormData) => ({
          ...prevFormData,
          country: selectedCountry,
          city: cityOptionsByCountry[selectedCountry][0], // Set default city for selected country
        }));
      } else if (name === "city") {
        setFormData((prevFormData) => ({ ...prevFormData, city: value }));
      } else {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    const {
      userFullName,
      userEmail,
      userphoneNum,
      pwd,
      pwdconfirm,
      Promocode,
      Street,
      Housenumber,
      vatNum,
      vatNumverify,
      Postalcode,
      authorizedRepPosition,
      authorizedRepFirstName,
      authorizedRepLastName,
      authorizedRepEmail,
      authorizedRepTelephone,
      authorizedRepID,
      authorizedRepDob,
      authorizedRepGender,
      howDidYouFindOut,
      verificationDocument,
      companyDocument,
      selectSocialMedia,
      role,
      country,
      city,
      ImnotRobot,
      CompanyName,
      companyInfo,
      country_of_trade,
    } = formData;

    if (formData?.role?.length === 0) {
      toast.error("Bitte wählen Sie Ihren Kontotyp");
      return;
    }

    // Check password length
    if (formData?.pwd?.length < 8) {
      toast.error("Das Passwort muss mindestens 8 Zeichen lang sein.");
      return;
    }

    if (formData?.vatNum !== formData?.vatNumverify) {
      toast.error("Vat verification number do not match with vat number.");
      return;
    }

    // Check VAT number length
    if (formData?.vatNum?.length < 5 || formData?.vatNum?.length > 20) {
      toast.error("Die Mehrwertsteuernummer muss zwischen 5 und 20 Zeichen lang sein.");
      return;
    }

    const phoneNumber = formData.userphoneNum;
    const phoneNumbers = formData.authorizedRepTelephone;
    const postalCode = formData.Postalcode;
    const numericRegex = /^[0-9]+$/;
    if (phoneNumber?.length < 7 || phoneNumber?.length > 15) {
      toast.error(
        "Die Telefonnummer muss ein numerischer Wert zwischen 7 und 15 Zeichen sein."
      );
      return;
    }
    if (phoneNumbers?.length < 7 || phoneNumbers?.length > 15) {
      toast.error(
        "Die Telefonnummer muss ein numerischer Wert zwischen 7 und 15 Zeichen sein."
      );
      return;
    }

    if (postalCode.length < 4 || postalCode.length > 7) {
      toast.error("Die Postleitzahl muss zwischen 4 und 7 Zeichen lang sein.");
      return;
    }

    if (!formData.verificationDocument) {
      toast.error("Das Hochladen eines Unternehmensinformationsdokuments ist erforderlich");
      return;
    }
    if (!formData.country_of_trade) {
      toast.error("Angabe des Handelslandes erforderlich");
      return;
    }
    if (!formData.companyDocument) {
      toast.error("Das Hochladen eines Ausweisdokuments ist erforderlich");
      return;
    }
    if (formData.city === "") {
      toast.error("Stadt ist erforderlich");
      return;
    }
    if (formData.ImnotRobot === "") {
      toast.error("Ich bin nicht Roboter Kontrollkästchen ist erforderlich");
      return;
    }

    // Validate age (18 years or older)
    const currentDate = new Date();
    const selectedDate = new Date(formData.authorizedRepDob);
    const ageDiff = currentDate.getFullYear() - selectedDate.getFullYear();

    if (
      ageDiff < 18 ||
      (ageDiff === 18 && currentDate.getMonth() < selectedDate.getMonth()) ||
      (ageDiff === 18 &&
        currentDate.getMonth() === selectedDate.getMonth() &&
        currentDate.getDate() < selectedDate.getDate())
    ) {
      // Display an error message if the user is younger than 18
      toast.error("Sie müssen 18 Jahre oder älter sein, um sich anzumelden.");
      return;
    }

    if (
      !userFullName ||
      !userEmail ||
      !userphoneNum ||
      !pwd ||
      !pwdconfirm ||
      // !Promocode ||
      !Street ||
      !Housenumber ||
      !vatNum ||
      !vatNumverify ||
      !Postalcode ||
      !authorizedRepPosition ||
      !authorizedRepFirstName ||
      !authorizedRepLastName ||
      !authorizedRepEmail ||
      !authorizedRepTelephone ||
      !authorizedRepDob ||
      !authorizedRepGender ||
      !authorizedRepID ||
      // !howDidYouFindOut ||
      !verificationDocument ||
      !companyDocument ||
      !selectSocialMedia ||
      !role ||
      !country ||
      !city ||
      !ImnotRobot ||
      !CompanyName ||
      !companyInfo ||
      !country_of_trade
    ) {
      toast.error("Bitte geben Sie alle erforderlichen Informationen an");
      return;
    }

    // You can add further validation or checks here as needed

    setIsLoading(true);
    try {
      const response = await dispatch(
        RegisterBuyerUser({
          name: userFullName,
          email: userEmail,
          phoneNum: userphoneNum,
          password: pwd,
          passwordcon: pwdconfirm,
          promocode: Promocode,
          street: Street,
          houseNumber: Housenumber,
          vatNumber: vatNum,
          vatNumberVerification: vatNumverify,
          postalCode: Postalcode,
          authorizedRepPosition: authorizedRepPosition,
          authorizedRepFirstName: authorizedRepFirstName,
          authorizedRepLastName: authorizedRepLastName,
          authorizedRepEmail: authorizedRepEmail,
          authorizedRepTelephone: authorizedRepTelephone,
          authorizedRepDob: authorizedRepDob,
          authorizedRepGender: authorizedRepGender,
          authorizedRepID: authorizedRepID,
          howDidYouFindOut: howDidYouFindOut,
          verificationDocument: verificationDocument,
          companyDocument: companyDocument,
          selectedSocialMedia: selectSocialMedia,
          role: role[0],
          country: country,
          city: city,
          ImnotRobot: ImnotRobot,
          CompanyName: CompanyName,
          companyInfo: companyInfo,
          country_of_trade: country_of_trade,
        })
      );

      if (response && response.payload) {
        const responseData = response.payload.data;

        if (
          responseData.message === "User created successfully" ||
          response.status === 201
        ) {
          toast.success("Erfolgreich registriert!");
          localStorage.setItem("email", formData.userEmail);
          resetForm();
          navigate("/emailverification");
        } else {
          toast.error(responseData.error || "Registrierung fehlgeschlagen");
        }
      } else {
        toast.error("Registrierung fehlgeschlagen");
      }
    } catch (error) {
      const errorMessage = error.response.data.error;
      console.log(errorMessage, "error");
      toast.error(errorMessage);
      toast.error(error.response.data.errors.email);

      setIsLoading(false);
      setFormData({ authorizedRepDob: "" });
      setSubmitted(false);
    }
  };

  const handleFileInputChange = (event) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const selectedFile = fileInput.files;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [fileInput.name]: Array.from(selectedFile),
      }));
    }
  };

  const handleCountryChange = (selectedOptions) => {
    const selectedCountries = selectedOptions.map((option) => option.label);

    setFormData((prevData) => ({
      ...prevData,
      country_of_trade: selectedCountries,
    }));
  };

  // Function to handle file validation
  const validateFile = (file) => {
    return file && file.size <= 2 * 1024 * 1024; // 2MB limit
  };
  const handleFileChange = (event, setSelectedFile, setErrorFile) => {
    handleFileInputChange(event);
    handleChange(event);

    const files = event.target.files;
    const validFiles = Array.from(files).filter((file) => validateFile(file));

    if (validFiles.length > 0) {
      setSelectedFile((prev) => {
        let prevArr = Array.from(prev ?? []);
        return [...prevArr, ...files];
      });
      setErrorFile(null);
    } else {
      setSelectedFile(null);
      setErrorFile("File size exceeds the limit of 2MB or invalid file type.");
    }
  };

  const handleFirstFileChange = (event) => {
    handleFileChange(event, setSelectedFirstFile, setErrorFirstFile);
  };

  const handleSecondFileChange = (event) => {
    handleFileChange(event, setSelectedSecondFile, setErrorSecondFile);
  };

  // Get Language Translation starts
  const { getTranslation } = useTranslation();

  useEffect(() => {
    // Retrieve form data from local storage
    const storedFormData = localStorage.getItem("formData");

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);
  const handleCountryChanges = (event) => {
    const selectedCountry = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      country: selectedCountry,
      city: "", // Reset the city when the country changes
    }));
  };

  return (
    <div className="regstreContainer">
      <div className="rawstaff-deals-frame-con">
        <div className="rawstaff-deals-frame-40">
          <div className="create-an-account">
            {getTranslation({ translationKey: "Ein Konto erstellen" })}
          </div>

          <div className="ggf-slogan-und-kurzer-satz-nochmal-zu-rastuff">
          Wo Qualität auf Markt trifft - Ihr Partner im Rohstoffhandel
          </div>

          <div className="rawstaff-deals-frame-43">
            <div className="rawstaff-deals-frame-42">
              <div className="select-your-account-type">
              Wählen Sie Ihren Kontotyp
              </div>
              <div className="rawstaff-deals-frame-41">
                <div className="rawstaff-deals-frame-36">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox"
                    label=""
                    aria-label="option 1"
                    className="circular-check"
                    value="buyer"
                    onChange={handleChangeRole}
                    name="role"
                    checked={selectedRole === "buyer"}
                  />
                  <div className="buyer">
                    {getTranslation({ translationKey: "Käufer" })}
                  </div>
                </div>

                <div className="rawstaff-deals-frame-37">
                  <Form.Check
                    type="checkbox"
                    id="custom-checkbox-6"
                    label=""
                    aria-label="option 2"
                    className="circular-check"
                    value="seller"
                    onChange={handleChangeRole}
                    name="role"
                    checked={selectedRole === "seller"}
                  />
                  <div className="seller">
                    {getTranslation({ translationKey: "Verkäufer" })}
                  </div>
                </div>

                {submitted && !selectedRole && (
                  <div className="invalid-feedback">Rolle ist erforderlich.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-information mb-4 mt-4">
        {getTranslation({ translationKey: "Kontoinformationen" })}
      </div>
      <Form
        method="POST"
        className="register-form-container"
        onSubmit={handleSubmit}
      >
        <Form.Group
          className="register-label"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            {getTranslation({ translationKey: "Benutzername" })}
          </Form.Label>
          <Form.Control
            className={`p-2 register-border ${
              submitted && formData.userFullName === "" ? "is-invalid" : ""
            }`}
            type="text"
            placeholder=""
            name="userFullName"
            value={formData.userFullName}
            onChange={handleChange}
          />
          {submitted && formData.userFullName === "" && (
            <div className="invalid-feedback">   {getTranslation({ translationKey: "Benutzername wird benötigt" })}</div>
          )}
        </Form.Group>
        <Form.Group
          className="register-label"
          controlId="exampleForm.ControlInput1"
        >
            
          <Form.Label>{getTranslation({ translationKey: "Passwort" })}</Form.Label>
          <Form.Control
            className={`p-2 register-border ${
              submitted && formData.pwd === "" ? "is-invalid" : ""
            }`}
            type="password"
            name="pwd"
            placeholder="******"
            value={formData.pwd}
            onChange={handleChange}
          />

          {submitted && formData.pwd === "" && (
            <div className="invalid-feedback">Passwort ist erforderlich.</div>
          )}
          {submitted && passwordError && (
            <div className="invalid-feedback">
             Das Passwort muss mindestens acht Zeichen plus Sonderzeichen und Großbuchstaben lang sein.
            </div>
          )}
          <article className="promocode Password-min">
          Das Passwort muss mindestens sechs Zeichen lang sein
          </article>
        </Form.Group>

        <Form.Group
          className="register-label"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Passwort wiederholen</Form.Label>
          <Form.Control
            className={`p-2 register-border ${
              submitted && formData.pwdconfirm === "" ? "is-invalid" : ""
            }`}
            type="password"
            name="pwdconfirm"
            placeholder="******"
            value={formData.pwdconfirm}
            onChange={handleChange}
          />
          {passwordErrors && <p className="text-danger">{passwordErrors}</p>}

          {submitted && formData.pwdconfirm === "" && (
            <div className="invalid-feedback">
             Eine Passwortbestätigung ist erforderlich.
            </div>
          )}
        </Form.Group>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <div className="promocode">
                <Form.Label>Referral code</Form.Label>
                <article>(optional)</article>
              </div>
              <Form.Control
                className="p-2 register-border"
                type="text"
                placeholder="EINGABE"
                name="Promocode"
                value={formData.Promocode}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    Promocode: e.target.value,
                  }))
                }
              />

              {/* {submitted && formData.Promocode === "" && (
        <div className="invalid-feedback">Promo code is required</div>
      )} */}
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <div className="registercheckbox">
          <div>
            {" "}
            {["checkbox"].map((type) => (
              <div key={type} className="mr-2 ">
                <Form.Check type={type} id={`checks-api-${type}`}>
                  <Form.Check.Input
                    type={type}
                    isValid
                    onChange={handleChange}
                    name="ImnotRobot"
                    value={true}
                    required
                  />
                </Form.Check>
              </div>
            ))}
          </div>

          <div className="i-m-not-a-robot">Ich bin kein Roboter!</div>
          <Form.Control.Feedback type="invalid">
          Ich bin kein Roboter ist erforderlich.
          </Form.Control.Feedback>
        </div>

        <div className="company-registration mb-6">Registrierung des Unternehmens</div>

        <Form.Group
          className="register-label company-registration-label"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            {getTranslation({ translationKey: "Land" })}
          </Form.Label>
          <Form.Select
            size="lg"
            onChange={handleCountryChanges}
            value={formData.country}
            name="country"
            style={{
              fontSize: "16px",
              height: "48px",
            }}
          >
            <option value="">
              {getTranslation({ translationKey: "Land auswählen" })}
            </option>
            {countryOptionss.map((country) => (
              <option key={country.code} value={country.value}>
                {country.name}
              </option>
            ))}
          </Form.Select>
          {submitted && formData.country === "" && (
            <div className="invalid-feedback">Land ist erforderlich.</div>
          )}
        </Form.Group>
        <Form.Group
          className="register-label"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Land des Handels</Form.Label>
          <Select
            className="trade-country"
            options={countryOptions}
            isMulti
            value={countryOptions.filter((option) =>
              (formData?.country_of_trade ?? []).includes(option.value)
            )}
            onChange={(selectedOptions) => handleCountryChange(selectedOptions)}
          />

          {submitted && (formData?.country_of_trade ?? []).length === 0 && (
            <div className="invalid-feedback">
            Das Handelsland ist erforderlich.
            </div>
          )}
        </Form.Group>
        <Form.Group
          className="register-label"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Name des Unternehmens</Form.Label>
          <Form.Control
            value={formData.CompanyName}
            onChange={handleChange}
            name="CompanyName"
            className={`p-2 register-border ${
              submitted && formData.CompanyName === "" ? "is-invalid" : ""
            }`}
            type="text"
          />
          {submitted && formData.CompanyName === "" && (
            <div className="invalid-feedback">Der Name des Unternehmens ist erforderlich.</div>
          )}
        </Form.Group>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1" // Unique controlId value
            >
              <Form.Label>Umsatzsteuer-Indentifikationsnummer</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.vatNum === "" ? "is-invalid" : ""
                }`}
                type="text"
                name="vatNum"
                placeholder="1546546546"
                value={formData.vatNum}
                onChange={handleChange}
              />
              {submitted && formData.vatNum === "" && (
                <div className="invalid-feedback">Umsatzsteuer-Identifikationsnummer ist erforderlich.</div>
              )}
              {submitted && formData.vatNum.length !== 10 && (
                <p className="invalid-feedback">
                  Vat number must be exactly 10 characters long.
                </p>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput2" // Unique controlId value
            >
              <Form.Label>Verifizierung Umsatzsteuer-Indentifikationsnummer</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.vatNumverify === "" ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="1546546546"
                name="vatNumverify"
                value={formData.vatNumverify}
                onChange={handleChange}
              />
              {submitted && formData.vatNumverify === "" && (
                <div className="invalid-feedback">
                  Vat verification number is required.
                </div>
              )}
              {vatNumberMatchError && (
                <p className="invalid-feedback">{vatNumberMatchError}</p>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Straße</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.Street === "" ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Queen street"
                name="Street"
                value={formData.Street}
                onChange={handleChange}
              />
              {submitted && formData.Street === "" && (
                <div className="invalid-feedback">Straße ist erforderlich.</div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Hausnummer</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.Housenumber === "" ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="#21 House,ly"
                name="Housenumber"
                value={formData.Housenumber}
                onChange={handleChange}
              />
              {submitted && formData.Housenumber === "" && (
                <div className="invalid-feedback">
                 Hausnummer ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Stadt</Form.Label>
              <Form.Control
                className={` ${
                  submitted && formData.city === "" ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="Type city or select from the list"
                name="city"
                value={formData.city}
                onChange={handleChange}
                list="cityOptions"
              />
              <datalist id="cityOptions">
                {cityOptionsByCountry[formData.country]?.map((city) => (
                  <option key={city.code} value={city.name} />
                ))}
              </datalist>
            </Form.Group>

            {submitted && formData.city === "" && (
              <div className="invalid-feedback">Stadt ist erforderlich.</div>
            )}
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Postleitzahl</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.Postalcode === "" ? "is-invalid" : ""
                }`}
                type="text"
                placeholder="15465"
                name="Postalcode"
                value={formData.Postalcode}
                onChange={handleChange}
              />
              {submitted && formData.Postalcode === "" && (
                <div className="invalid-feedback">Postleitzahl ist erforderlich</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>
                {getTranslation({ translationKey: "Email" })}
              </Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.userEmail === "" ? "is-invalid" : ""
                }`}
                type="email"
                placeholder="Name@beispiel.com"
                name="userEmail"
                value={formData.userEmail}
                onChange={handleChange}
              />
              {submitted && formData.userEmail === "" && (
                <div className="invalid-feedback"> Email ist erforderlich</div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>
                {getTranslation({ translationKey: "Telefon" })}
              </Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.userphoneNum === "" ? "is-invalid" : ""
                }`}
                type="text"
                name="userphoneNum"
                placeholder="+95131651616"
                value={formData.userphoneNum}
                onChange={handleChange}
              />
              {submitted && formData.userphoneNum === "" && (
                <div className="invalid-feedback">
                  Telefon ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4">
            <Form.Group
              // className={`register-label ${submitted && formData.companyInfo === '' ? 'is-invalid' : ''}`}
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>
                {getTranslation({ translationKey: "Informationen zum Unternehmen" })}
              </Form.Label>
              <Form.Select
                size="lg"
                onChange={handleChange}
                value={formData.companyInfo}
                name="companyInfo"
                style={{
                  fontSize: "16px",
                  height: "48px",
                }}
                className={`p-2 register-border ${
                  submitted && formData.companyInfo === "" ? "is-invalid" : ""
                }`}
              >
                <option value="">
                  {getTranslation({ translationKey: "Wählen Sie eine Option" })}
                </option>
                <option value="Agricultural Industry">
                  Agricultural Industry
                </option>
                <option value="Agricultural Inputs Industry">
                  Agricultural Inputs Industry
                </option>
                <option value="Agriculture Technology (AgTech) Sector">
                  Agriculture Technology (AgTech) Sector
                </option>
                <option value="Biotechnology Industry">
                  Biotechnology Industry
                </option>
                <option value="Chemical Industry">Chemical Industry</option>
                <option value="Clinical Research Organizations (CROs)">
                  Clinical Research Organizations (CROs)
                </option>
                <option value="Consulting">Consulting</option>
                <option value="Cosmetics and Personal Care Industry">
                  Cosmetics and Personal Care Industry
                </option>
                <option value="E-commerce Industry">E-commerce Industry</option>
                <option value="Environmental Sciences">
                  Environmental Sciences
                </option>
                <option value="Fitness and Wellness Industry">
                  Fitness and Wellness Industry
                </option>
                <option value="Food and Beverage Industry">
                  Food and Beverage Industry
                </option>
                <option value="Food Safety and Quality Assurance Sector">
                  Food Safety and Quality Assurance Sector
                </option>
                <option value="Healthcare Industry">Healthcare Industry</option>
                <option value="Health and Wellness Services">
                  Health and Wellness Services
                </option>
                <option value="Manufacturing Sector">
                  Manufacturing Sector
                </option>
                <option value="Nutraceutical Industry">
                  Nutraceutical Industry
                </option>
                <option value="Packaging Industry">Packaging Industry</option>
                <option value="Packaging Materials Industry">
                  Packaging Materials Industry
                </option>
                <option value="Pharmaceutical Industry">
                  Pharmaceutical Industry
                </option>
                <option value="Retail Industry">Retail Industry</option>
                <option value="Supply Chain and Logistics Industry">
                  Supply Chain and Logistics Industry
                </option>
                <option value="Testing Laboratories">
                  Testing Laboratories
                </option>
                <option value="Others">Others</option>
              </Form.Select>

              {submitted && formData.companyInfo === "" && (
                <div className="invalid-feedback">
                  Firmeninformation ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}></Col>
        </Row>

        <Row className="Upload-Document">
          <Col xs={12} md={6}>
            <Row className="Upload-Document-row">
              <Col>
                <Form.Label>Dokument hochladen</Form.Label>
              </Col>
              <Col>
                <Form.Label
                  style={{
                    color: "#AEAEAE",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  JPG, PNG, max 2MB Datei auswählen
                </Form.Label>
              </Col>
            </Row>
          </Col>

          <Col className="Choose-File">
            <label
              htmlFor="fileInput1"
              style={{
                background: " #ededed",
                borderRadius: "4px",
                width: "148px",
                height: "48px",
                color: " #2e2e2e",
                textAlign: "left",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
              }}
              className="custom-file-button"
            >
              <Upload /> {getTranslation({ translationKey: "Datei auswählen" })}
            </label>
            <input
              id="fileInput1"
              type="file"
              className="hidden-file-input d-none"
              onChange={handleFirstFileChange}
              name="verificationDocument"
              multiple
            />
            {errorFirstFile && (
              <article
                style={{
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  width: "300px",
                  height: "48px",
                  display: "flex",
                  paddingLeft: "0.5rem",
                  alignItems: "center",
                  fontFamily: "Plus Jakarta Sans, sans-serif",
                  fontSize: "12.5px",
                  marginBottom: "0.5rem",
                  color: "red",
                }}
              >
                {errorFirstFile}
              </article>
            )}
            {selectedFirstFile && (
              <div>
                <ul className="multiple-upload">
                  {selectedFirstFile.map((file, index) => (
                    <li className="multiple-uploads" key={index}>
                      {file.name}
                      <span
                        className="mb-7 file-list"
                        onClick={(e) => {
                          setSelectedFirstFile((prev) =>
                            Array.from(prev).filter(
                              (f) => f?.name !== file?.name
                            )
                          );
                        }}
                      >
                        {" "}
                        <article className="close-x">
                          <Close className="close-xs" />
                        </article>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {submitted && formData.verificationDocument === "" && (
              <div className="invalid-feedback">Datei hochladen ist erforderlich.</div>
            )}
          </Col>
          <article>(Sie können mehrere Dateien hochladen)</article>
        </Row>

        <br />
        <div className="authorized-representative">
        Autorisierter Vertreter
        </div>
        <br />
        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlInput1">
          <Form.Label>Position</Form.Label>
          <Form.Control
            className={`p-2 register-border ${
              submitted && formData.authorizedRepPosition === ""
                ? "is-invalid"
                : ""
            }`}
            type="text"
            placeholder=""
            name="authorizedRepPosition"
            value={formData.authorizedRepPosition}
            onChange={handleChange}
          />
          {submitted && formData.authorizedRepPosition === "" && (
            <div className="invalid-feedback">
              Eine autorisierte Position ist erforderlich.
            </div>
          )}
        </Form.Group>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Vorname</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepFirstName === ""
                    ? "is-invalid"
                    : ""
                }`}
                type="fristname"
                placeholder=""
                name="authorizedRepFirstName"
                value={formData.authorizedRepFirstName}
                onChange={handleChange}
              />
              {submitted && formData.authorizedRepFirstName === "" && (
                <div className="invalid-feedback">
                 Autorisierter Vorname ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Nachname</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepLastName === ""
                    ? "is-invalid"
                    : ""
                }`}
                type="lastname"
                placeholder=""
                name="authorizedRepLastName"
                value={formData.authorizedRepLastName}
                onChange={handleChange}
              />
              {submitted && formData.authorizedRepLastName === "" && (
                <div className="invalid-feedback">
                Autorisierter Nachname ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>
                {getTranslation({ translationKey: "Email" })}
              </Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepEmail === ""
                    ? "is-invalid"
                    : ""
                }`}
                type="email"
                name="authorizedRepEmail"
                placeholder="Name@beispiel.com"
                value={formData.authorizedRepEmail}
                onChange={handleChange}
              />
              {submitted && formData.authorizedRepEmail === "" && (
                <div className="invalid-feedback">
                 Autorisierte Email ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Telefon</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepTelephone === ""
                    ? "is-invalid"
                    : ""
                }`}
                type="text"
                name="authorizedRepTelephone"
                placeholder="+95131651616"
                value={formData.authorizedRepTelephone}
                onChange={handleChange}
              />
              {submitted && formData.authorizedRepTelephone === "" && (
                <div className="invalid-feedback">
                Autorisiertes Telefon ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="register-label" controlId="gender">
              <Form.Label>Geschlecht</Form.Label>
              <Form.Select
                size="lg"
                name="authorizedRepGender"
                style={{
                  fontSize: "16px",
                  height: "48px",
                }}
                value={formData.authorizedRepGender}
                onChange={handleChange}
              >
                <option value="">Eine Option auswählen</option>
                <option value={getTranslation({ translationKey: "Male" })}>
                  Male
                </option>
                <option value={getTranslation({ translationKey: "Female" })}>
                  Female
                </option>
                <option value={getTranslation({ translationKey: "Diverse" })}>
                  Diverse
                </option>
              </Form.Select>
              {submitted && formData.authorizedRepGender === "" && (
                <div className="invalid-feedback">
                  Authorized Gender is Required.
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="register-label" controlId="dob">
              <Form.Label>Geburtsdatum:</Form.Label>
              <Form.Control
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepDob === ""
                    ? "is-invalid"
                    : ""
                }`}
                type="date"
                name="authorizedRepDob"
                placeholder=""
                value={formData.authorizedRepDob}
                onChange={handleChange}
              />
              {submitted && formData.authorizedRepDob === "" && (
                <div className="invalid-feedback">
                  Genehmigtes Geburtsdatum ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}></Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group
              className="register-label"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Eine Option auswählen</Form.Label>
              <Form.Select
                size="lg"
                style={{
                  fontSize: "16px",
                  height: "48px",
                }}
                name="authorizedRepID"
                value={formData.authorizedRepID}
                onChange={handleChange}
                className={`p-2 register-border ${
                  submitted && formData.authorizedRepID === ""
                    ? "is-invalid"
                    : ""
                }`}
              >
                <option value="">Eine Option auswählen</option>

                <option>
                  {getTranslation({
                    translationKey: "Personalausweis (Identity Card)",
                  })}
                </option>
                <option>
                  {getTranslation({
                    translationKey: "Reisepass (Passport)",
                  })}
                </option>
                <option>
                  {getTranslation({
                    translationKey: "Aufenthaltstitel (Residence Permit)",
                  })}
                </option>
                <option>
                  {getTranslation({
                    translationKey:
                      "Meldebescheinigung (Registration Certificate)",
                  })}
                </option>
                <option>
                  {getTranslation({
                    translationKey: "Führerschein (Driver's License)",
                  })}
                </option>
              </Form.Select>
              {submitted && formData.authorizedRepID === "" && (
                <div className="invalid-feedback">
                Hochladen Identifikationsdokument Typ ist erforderlich.
                </div>
              )}
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <Row className="Upload-Document">
          <Col>
            <Row className="Upload-Document-row">
              <Col>
                <Form.Label>Dokument hochladen</Form.Label>
                
              </Col>
              <Col>
                <Form.Label
                  style={{
                    color: "#AEAEAE",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  JPG, PNG, max 2MB Datei auswählen
                </Form.Label>
              </Col>
            </Row>
          </Col>
          <Col className="Choose-File">
            <label
              htmlFor="fileInput2" // Updated ID
              style={{
                background: " #ededed",
                borderRadius: "4px",
                width: "148px",
                height: "48px",
                color: " #2e2e2e",
                textAlign: "left",
                fontFamily: "Plus Jakarta Sans, sans-serif",
                fontWeight: "600",
                fontSize: "14px",
              }}
              className="custom-file-button"
            >
              <Upload /> {getTranslation({ translationKey: "Datei auswählen" })}
            </label>
            <input
              id="fileInput2"
              type="file"
              className="hidden-file-input d-none"
              onChange={handleSecondFileChange}
              name="companyDocument"
              multiple // Add this attribute for multiple file selection
            />

            {errorSecondFile && (
              <article
                style={{
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  width: "300px",
                  height: "48px",
                  display: "flex",
                  paddingLeft: "0.5rem",
                  alignItems: "center",
                  fontFamily: "Plus Jakarta Sans, sans-serif",
                  fontSize: "12.5px",
                  marginBottom: "0.5rem",
                  color: "red",
                }}
              >
                {errorSecondFile}
              </article>
            )}
            {selectedSecondFile && (
              <div>
                <ul className="multiple-upload">
                  {selectedSecondFile.map((file, index) => (
                    <li className="multiple-uploads" key={index}>
                      {file.name}
                      <span
                        className="mb-7 file-list"
                        onClick={(e) => {
                          setSelectedSecondFile((prev) =>
                            Array.from(prev).filter(
                              (f) => f?.name !== file?.name
                            )
                          );
                        }}
                      >
                        <article className="close-x">
                          <Close className="close-xs" />
                        </article>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Col>
          <article>(Sie können mehrere Dateien hochladen)</article>
          {submitted && formData.companyDocument === "" && (
            <div className="invalid-feedback">
              Upload Unternehmen Dokument ist erforderlich.
            </div>
          )}
        </Row>

        <div className="mt-4 rawstaff-deals-how">
          <br />
          <div className="rawstaff-deals-frame-161">
            <div className="how-did-you-find-out-about-us">
              {getTranslation({ translationKey: "Wie sind Sie auf uns aufmerksam geworden?" })}
            </div>
            <br />
            <div className="register-button5">
              <Form.Group
                className="register-label"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  className="p-2 register-border"
                  type="text"
                  name="howDidYouFindOut"
                  placeholder="hier schreiben"
                  value={formData.howDidYouFindOut}
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <br />
          <div className="rawstaff-deals-frame-49">
            <div className="rawstaff-deals-frame-36">
              <Form.Check
                type="checkbox"
                id="custom-checkbox-1"
                label=""
                aria-label="option 3"
                className="circular-check"
                name="selectSocialMedia"
                onChange={handleChangeSocialMedia}
                value="google"
                checked={selectedSocialMedia === "google"}
              />

              <div className="google">Google</div>
            </div>
            <div className="rawstaff-deals-frame-46">
              <Form.Check
                type="checkbox"
                id="custom-checkbox-2"
                label=""
                aria-label="option 4"
                className="circular-check"
                name="selectSocialMedia"
                onChange={handleChangeSocialMedia}
                value="socialMedia"
                checked={selectedSocialMedia === "socialMedia"}
              />
              <div className="social-media">
                {getTranslation({ translationKey: "Soziale Medien (LinkedIn)" })}
              </div>
            </div>
            <div className="rawstaff-deals-frame-47">
              <Form.Check
                name="selectSocialMedia"
                onChange={handleChangeSocialMedia}
                value="recommendation"
                type="checkbox"
                id="custom-checkbox-3"
                label=""
                aria-label="option 5"
                className="circular-check"
                checked={selectedSocialMedia === "recommendation"}
              />
              <div className="recommendation">Empfehlung</div>
            </div>

            <div className="rawstaff-deals-frame-48">
              <Form.Check
                type="checkbox"
                id="custom-checkbox-4"
                label=""
                aria-label="option 6"
                className="circular-check"
                name="selectSocialMedia"
                onChange={handleChangeSocialMedia}
                value="Others"
                checked={selectedSocialMedia === "Others"}
              />
              <div className="others">
                {getTranslation({ translationKey: "Sonstige" })}
              </div>
            </div>
          </div>
          <div className="register-button6">
            <button type="submit" className="downloads">
              {getTranslation({ translationKey: "Weiter" })}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
export default RawstuffRegister;
