import React, { useState } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SupportModal from './SupportModal';
import { Collapse } from 'react-bootstrap';

const FAQs = () => {
  const [faqs, setFaqs] = useState([
    {
      question: 'Can I change my plan later?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false,
    },
    {
      question: 'What is your cancellation policy?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false,
    },
    {
      question: 'Can I cancel my subscription?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false,
    },
    {
      question: 'Can I change my plan later?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false
    },
    {
      question: 'How does billing work?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false,
    },
    {
      question: 'How do I change my account email?',
      answer: 'Yes, you can change your plan at any time. lorem ipsum lorem ipsum lorem ipsum',
      showAnswer: false,
    },
  ]);

  const toggleAnswer = (index) => {
    const updatedFaqs = [...faqs];
    updatedFaqs[index].showAnswer = !updatedFaqs[index].showAnswer;
    setFaqs(updatedFaqs);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  return (
    <div className='top-300 containerr'>
      <div className='text-center my-5'>
        <p className='faqfaq'>FAQs</p>
        <div className='headfaq'>Frequently asked questions</div>
        <div className='text-center faqdsc mt-3'>
          <p>
          Everything you need to know about the product and billing. Can’t find the answer
          </p>
          
          <p>you’re looking for? Please chat to our friendly team.</p> 
        </div>
      </div>
      <div className='col col-md-9 col-lg-6 mx-auto col-sm-12'>
        <div className=''>
          {faqs.map((faq, index) => (
            <div className='' key={index}>
              <div className='m-1'>
                <div className={`faqs ${faq.showAnswer ? 'actives' : ''}`}>
                  <div className='d-flex gap-2' onClick={() => toggleAnswer(index)}>
                    {faq.showAnswer ?
                      <RemoveCircleOutlineIcon sx={{ color: '#98A2B3' }} />
                      : <AddCircleOutlineIcon sx={{ color: '#98A2B3' }}/>}{' '}
                    <p className='faq'>{faq.question}</p>
                  </div>
                  {faq.showAnswer && <p className='px-4'>{faq.answer}</p>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='text-center stlh container'>
            <div className='sthqh mb-4'>Still have questions?</div>
            <p className='sthqp'>Join over 4,000+ startups already growing with Untitled.</p>
            <div className='d-flex'>
              <button className='btnn'>
                <a href="mailto:info@rawstuff.deals" className='text-black'>
                  Email us
                </a>
              </button>
              <button className='btnnc' onClick={() => handleShow()}>Chat to our team</button>
            </div>
      </div>
      <SupportModal
          show={show}
          handleClose={handleClose}
      />
    </div>


  );
};

export default FAQs;