import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import "./SideBar.css";
import buydb from "../../Constants/Contents/DashboardsidebarENDE";

function SideBar() {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [totalOrder, setTotalOrder] = useState("");
  const [link_name, setLink_name] = useState("");

  const url = useParams();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  const fetchOrders = async () => {
    const newToken = JSON.parse(localStorage?.getItem("token"))?.token;

    if (!newToken) {
      return;
    }
    try {
      const response = await axios.get(
        "https://api.rawstuff.deals/api/v1/orders/getUserOrders",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${newToken}`,
          },
        }
      );
      const data = response.data;
      setTotalOrder(data.count);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setTotalOrder(0);
    }
  };

  useEffect(() => {
    setLink_name(url["*"]);
    fetchOrders();
  }, [url]);

  const renderSwitchDashboardLink = () => {
    if (parseProfiledata?.role === "seller") {
      return (
        <a
          style={{ color: "white" }}
          href="https://api.rawstuff.deals/products"
        >
          Switch to Seller Dashboard
        </a>
      );
    } else {
      return (
        <Link to="/dashboard" style={{ color: "white" }}>
          Dashboard
        </Link>
      );
    }
  };

  const profileData = localStorage?.getItem("token");
  const parseProfiledata = JSON.parse(profileData);
  const partnerStatus = parseProfiledata?.user?.partner_status;

  if (!parseProfiledata) {
    navigate("/login");
    return;
  }

  const Upgrade = () => {
    setLink_name("upgrade");
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
    setLink_name(url["*"]);
  };

  return (
    <div>
      <div className="buyer-dashboard" style={{ marginBottom: "0%" }}>
        <ul className="list-group sidebar">
          <li style={{ background: "#000000" }} className="list-group-item">
            <i className="fa fa-dashboard" style={{ color: "white" }}>
              &nbsp;&nbsp;
            </i>
            {renderSwitchDashboardLink()}
          </li>

          <li
            className={`list-group-item ${
              link_name === "purchased-items/total" && "link-checked"
            }`}
          >
            <Link
              to="/dashboard/purchased-items/total"
              style={{ color: "black" }}
            >
              <i className="fa fa-shopping-cart">&nbsp;&nbsp;</i>
              {buydb.ordereditemsde}{" "}
              <span className="badge badge-dark badge-pill">{totalOrder}</span>
            </Link>
          </li>

          <li className="list-group-item">
            <Link to="/wishlist" style={{ color: "black" }}>
              <i className="fa fa-heart">&nbsp;&nbsp;</i>
              {buydb.wishlistde}
            </Link>
          </li>

          <li
            className={`list-group-item ${
              link_name === "membership" && "link-checked"
            }`}
          >
            <Link to="/membership" style={{ color: "black" }}>
              <i className="fa fa-shield">&nbsp;&nbsp;</i>
              {buydb.membershipde}
            </Link>
          </li>

          <li
            className={`list-group-item ${
              link_name === "add-ticket" && "link-checked"
            }`}
          >
            <Link to="/dashboard/add-ticket" style={{ color: "black" }}>
              <i className="fa fa-message">&nbsp;&nbsp;</i>
              {buydb.addticketde}
            </Link>
          </li>

          {partnerStatus === null ? (
            <li
              className={`list-group-item ${
                link_name === "upgrade" && "link-checked"
              }`}
              onClick={() => Upgrade()}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-certificate">&nbsp;&nbsp;</i>
              {buydb.upgradetopartnerde}
            </li>
          ) : (
            <li
              className={`list-group-item ${
                link_name === "partner-overview" && "link-checked"
              }`}
            >
              <Link to="/dashboard/partner-overview" style={{ color: "black" }}>
                <i className="fa fa-users">&nbsp;&nbsp;</i>
                {buydb.partneroverviewde}
              </Link>
            </li>
          )}

          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => handleClose()}
            parseProfiledata={parseProfiledata}
          />

          <li
            className={`list-group-item ${
              link_name === "managewarehouse" && "link-checked"
            }`}
          >
            <Link to="/dashboard/managewarehouse" style={{ color: "black" }}>
              <i className="fa fa-map-marker">&nbsp;&nbsp;</i>
              {buydb.warehousede}
            </Link>
          </li>

          <li
            className={`list-group-item ${
              link_name === "profile" && "link-checked"
            }`}
          >
            <Link to="/dashboard/profile" style={{ color: "black" }}>
              <i className="fa fa-user">&nbsp;&nbsp;</i>
              {buydb.profilede}
            </Link>
          </li>

          <li
            className={`list-group-item ${
              link_name === "edit-profile" && "link-checked"
            }`}
          >
            <Link to="/dashboard/edit-profile" style={{ color: "black" }}>
              <i className="fa fa-pencil">&nbsp;&nbsp;</i>
              {buydb.editprofilede}
            </Link>
          </li>

          <li
            onClick={() => logout("Logout")}
            className={`list-group-item`}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-home">&nbsp;&nbsp;</i>
            {buydb.logoutde}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 class="modal-title" id="exampleModalLabel">
            &nbsp;<i class="fa fa-user-circle-o">&nbsp;</i>Jetzt Partner Werden
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="modal-body">
          <p>
            {/* Hallo {props?.parseProfiledata?.user?.name}, vielen Dank für Ihr
            Interesse, einer unserer verifizierten Partner zu werden. Sie müssen
            uns jedoch eine Nachricht zukommen lassen, um eine Genehmigung zu
            erhalten. Hallo Einkäufer, vielen Dank für Ihr Interesse, einer
            unserer verifizierten Partner zu werden. Sie müssen uns jedoch eine
            Nachricht zukommen lassen, um eine Genehmigung zu erhalten.* */}
            Wir schätzen Ihr Interesse, sich als verifizierter Partner bei uns
            anzumelden. Senden Sie uns einfach eine Nachricht, um den
            Genehmigungsprozess in Gang zu setzen und machen Sie den
            entscheidenden Schritt hin zu Ihrem erfolgreichen Upgrade.
            IhrAnliegen ist uns wichtig und wir garantieren eine zügige
            Bearbeitung Ihrer Anfrage.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props?.onHide} variant="secondary">
          Schließen
        </Button>

        <a
          href="mailto:info@rawstuff.deals"
          type="button"
          class="btn btn-secondary"
        >
          {/* <i class="fa fa-envelope">&nbsp;</i> */}
          {/* Nachricht Rawstuff Deals */}
          Rawstuff Deals
        </a>
      </Modal.Footer>
    </Modal>
  );
}
