import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LogIn from "./components/Regstration/LogIn";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetSuccess from "./components/ForgotPassword/ResetSuccess";
import Home from "./components/Main/Home";
import ResultPage from "./components/SearchEngine/ResultPage/ResultPage";
import ShoppingPage from "./components/ShoppingCart/ShoppingCartPage";
import "react-toastify/dist/ReactToastify.css";
import ProductDetailPage from "./components/productDetailPage/ProductDetailsPage";
import Error from "./components/Error";
import Transaction from "./components/Order/Transaction";
import ContactSupport from "./components/Order/ContactSupport";
import DashboardPage from "./components/Dashboard/DashboardPage";
import NavBar from "./components/NavBar/NavBar";
import Footers from "./components/Footer/Footers";
import ShippingMethod from "./components/ShoppingCart/ShippingMethod/ShippingMethod";
import { useDispatch } from "react-redux";
import { fetchProducts } from "./redux/Slice/ProductSlice";
import { getToCart } from "./redux/Slice/getCartSlice";
import Details from "./components/Support/Details.jsx";

import RawstuffRegister from "./components/Regstration/RawstuffRegister";
import UpdatedMarketPlace from "./components/MarketPlace/UpdatedMarketPlace/UpdatedMarketPlace";
import SearchResultPage from "./components/SearchResultPage/SearchResultPage";
import { InstantSearch } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import MemberShipPage from "./components/MemberShipPage/MemberShipPage";
import MemberShipPageSeller from "./components/MemberShipPage/MembershipPageSeller";
import WishList from "./components/WhishList/WishList.jsx";
import Aboutus from "./components/About-us/Aboutus";
import ContactMe from "./components/Regstration/ContactMe";
import EmailVerificationPage from "./components/Regstration/EmailVerificationPage";
import EmailVerificationSuccessful from "./components/Regstration/EmailVerificationSuccessful";
import Payment from "./components/Payment/Payment";
import ProceedToPayment from "./components/Payment/ProceedToPayment";
import AccountUnderReview from "./components/Regstration/AccountUnderReview";
import Support from "./components/Support/Support.jsx";
import FAQs from "./components/Support/FAQs.jsx";
import PrivacyPolicy from "./components/Support/PrivacyPolicy.jsx";
import Impressum from "./components/Support/Impressum.jsx";
import Termsandconditions from "./components/Support/Terms-and-conditions.jsx";
import PaymentSuccess from "./components/MarketPlace/PaymentSuccess.jsx";
import PurchasedItemDetails from "./components/Dashboard/PurchasedItemDetails.jsx";
import { ScrollToTop } from "./components/Support/ScrolToTop.jsx";
import Agbs from "./components/Agb/Agbs.jsx";
import RP_Detail1 from "./components/BlogPost/RP_Detail1.jsx";
import RP_Detail2 from "./components/BlogPost/RP_Detail2.jsx";

// "2ba2fca2abd811a5f4950a36abc5d657",
const searchClient = algoliasearch(
  "UVOR1LNVPH",
  "5bcf686743918539e4d5952618f45b03"
);

export const index = searchClient.initIndex("products");

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(getToCart());
  }, [dispatch]);

  return (
    <InstantSearch indexName="products" searchClient={searchClient}>
      <BrowserRouter>
        <ScrollToTop />
        <NavBar />
        <ToastContainer position="top-right" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/result/:search" element={<SearchResultPage />} />
          <Route path="/accountUnderReview" element={<AccountUnderReview />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/ForgotPassword" element={<ResetSuccess />} />
          <Route path="/ResetPassword" element={<ForgotPassword />} />
          <Route path="/checkouts" element={<Transaction />} />
          <Route path="/contact" element={<ContactSupport />} />
          <Route path="/register" element={<RawstuffRegister />} />
          <Route path="/resultPage" element={<ResultPage />} />
          <Route path="/productDetailPage" element={<ProductDetailPage />} />
          <Route path="/shoppingcart" element={<ShoppingPage />} />
          <Route path="/shippingAddress" element={<ShippingMethod />} />
          <Route path="/marketplace" element={<UpdatedMarketPlace />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/membership" element={<MemberShipPage />} />
          <Route path="/membership-seller" element={<MemberShipPageSeller />} />
          <Route
            path="/purchased-items-details/:id"
            element={<PurchasedItemDetails />}
          />
          <Route path="/support" element={<Support />} />
          <Route path="/support/details/:title" element={<Details />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<Termsandconditions />}
          />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/agbs" element={<Agbs />} />
          <Route path="/recentPost1" element={<RP_Detail1 />} />
          <Route path="/recentPost2" element={<RP_Detail2 />} />
          <Route path="/contactme" element={<ContactMe />} />

          <Route path="/dashboard/*" element={<DashboardPage />} />
          <Route path="/*" element={<Error />} />
          <Route
            path="/emailverification"
            element={<EmailVerificationPage />}
          />
          <Route
            path="/emailVerificationSuccessful"
            element={<EmailVerificationSuccessful />}
          />
          <Route path="/payment" element={<Payment />} />
          <Route path="/proceedtopayment" element={<ProceedToPayment />} />

          <Route path="/password-reset-success" element={<ResetSuccess />} />
        </Routes>
        <Footers />
      </BrowserRouter>
    </InstantSearch>
  );
}

export default App;
