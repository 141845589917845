// AccountUnderReview.js

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './AccountUnderReview.css'; // Import your custom CSS file

const AccountUnderReview = () => {

  return (
    <div className="account-under-review">
        <Container> 
      <Row>
        <Col  xs={12}  className="account-under-reviews">
         <div className='account_Under'>
         <h2>Account review in progress</h2>
          <h3>Ready to go soon!</h3>
         </div>
         <p className='text-left mb-3'>
          Hello and welcome!
          </p>
          <p className='text-left mb-2'>
          We are delighted that you have chosen us. We are currently checking your registration details with the utmost care to ensure you have the best user
experience. Therefore, it is not yet possible to log in to your account.
Don't worry, this phase is only temporary. As soon as your data has been checked and approved, you will be informed immediately by our system and
your adventure can begin.
          </p>

          <p className='text-left mb-2'>
          Please check back soon - it's worth it! We are almost ready and can't wait to have you on board.

          </p>
          <p className='text-left mb-2'>Thank you for your understanding and patience. We are getting everything ready for your perfect start!</p>
          <br />
          <br />

          <Button className='acc-go-back-bt'>
            <a href="/"> &lt;  Go back</a>
          </Button>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default AccountUnderReview;
