const buydb = {
  dashboarden: "Dashboard",
  dashboardde: "Dashboard",
  ordereditemsen: "Ordered Items",
  ordereditemsde: "Bestellte Artikel",
  wishlisten: "My Wishlist",
  wishlistde: "Mein Merkzettel",
  membershipen: "Membership Subscription",
  membershipde: "Abonnement",
  addticketen: "Add Ticket",
  addticketde: "Ticket hinzufügen",
  partneroverviewen: "Partner Overview",
  partneroverviewde: "Partner Übersicht",
  upgradetopartneren: "Upgrade to Partner",
  upgradetopartnerde: "Partner werden",
  warehouseen: "Manage Address",
  warehousede: "Adressen verwalten",
  profileen: "Profile",
  profilede: "Profil",
  editprofileen: "Edit Profile",
  editprofilede: "Profil bearbeiten",
  logouten: "Logout",
  logoutde: "Ausloggen", //stop
  statusen: "Status",
  statusde: "Status",
  typeen: "Type",
  typede: "Typ",
  expireen: "Expires",
  expirede: "Endet",
  renewen: "Renew",
  renewde: "Erneuern",
  cancelen: "Cancel plan",
  cancelde: "Cancel",
  viewallen: "View all",
  viewallde: "alle ansehen",
  totalorderen: "Total Orders",
  totalorderde: "Gesamtbetrag",
  pendingorderen: "Pending Orders",
  pendingorderde: "Ausstehende Bestellung",
  emailen: "Email",
  emailde: "Email",
  telen: "Telephone",
  telde: "Telefon",
  cityen: "City",
  cityde: "Stadt",
  zipcodeen: "Zip Code",
  zipcodede: "Postleitzahl",
  adressen: "Adress",
  adressde: "Adresse",
  accinfoen: "Account Information",
  accinfode: "Kontoinformationen",
  planinfoen: "Membership Plan",
  planinfode: "Abonnement Plan",
};

Object.freeze(buydb);

export default buydb;
