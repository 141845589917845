import React from 'react'
import'./RP_Details.css'
import { images } from '../../Constants';

const RP_Detail1 = () => {
  return (
    <div className="RP_Detail-container">
      <div className="container border px-4 pt-4 pb-5">
        <div className="recentPost-banner">
          <img src={images.recent_post1} alt="recentPost1" />
        </div>
        <h2 className="mb-2">Pressemitteilung:</h2>
        <p className="mb-1 pb-2">
          <strong>Rawstuff Deals:</strong>Die neue Ära im globalen
          Rohstoffhandel beginnt Rawstuff Deals, die innovative B2B-Plattform
          für den weltweiten Rohstoffhandel, ist ab sofort live und verändert
          die Art und Weise, wie Unternehmen Rohstoffe beschaffen und verkaufen.
        </p>

        <p className="mb-1  pb-2">
          Aus unserer Historie heraus erkannten wir schon früh den hohen Bedarf
          an Transparenz im Rohstoffmarkt. In der aktuellen Situation stellt es
          einen Rohstoffhändler immer wieder vor eine Herausforderung, das
          gesamte Marktpotential auszuschöpfen. Auf der Gegenseite stellt es
          einen Einkäufer vor das Problem, den gesamten Markt im Überblick zu
          behalten.
        </p>
        <p className="mb-1  pb-2">
          Daher haben wir uns die Aufgabe gestellt, ein passendes Werkzeug für
          dieses Problem zu erstellen. Mit diesem Werkzeug ist es möglich, in
          kürzester Zeit auf der Angebotsseite eine qualifiziert hohe Reichweite
          zu erzielen. Auf der Gegenseite kann hiermit der Einkäufer
          schnellstmöglich verschiedene Angebote sortieren, auswerten und
          kaufen. Für die einzelnen Unternehmen bedeutet dies eine massive
          Zeitersparnis.
        </p>
        <p className="mb-1  pb-2">
          "Wir sind begeistert, Rawstuff Deals der Welt vorzustellen und einen
          neuen Standard im Rohstoffhandel zu setzen", sagte Sebastian
          Schneider, CEO von Rawstuff Deals. "Unsere Plattform bietet eine
          breite Palette von Dienstleistungen, darunter eine fortschrittliche
          Suchmaschine und umfassende Verifizierungsprozesse, um die Qualität
          und Zuverlässigkeit aller auf unserer Plattform angebotenen Produkte
          zu gewährleisten."
        </p>
        <p className="mb-1  pb-2">
          Rawstuff Deals ermöglicht es Nutzern, Angebote verschiedener Verkäufer
          mühelos zu vergleichen und fundierte Einkaufs- oder
          Verkaufsentscheidungen zu treffen. Durch einen sicheren Handelsbereich
          werden alle Transaktionen vertraulich und geschützt abgewickelt.
        </p>
        <p className="mb-1  pb-2">
          "Unser Ziel ist es, eine Gemeinschaft aufzubauen, in der Verkäufer und
          Käufer gleichermaßen von transparenten, fairen und effizienten
          Handelsbedingungen profitieren", fügte Sebastian Schneider hinzu.
        </p>
        <p className="mb-1  pb-2">
          Rawstuff Deals ist stolz darauf, eine Schlüsselrolle in der
          Modernisierung des Rohstoffhandels zu spielen und freut sich darauf,
          Unternehmen auf ihrem Handelsweg zu begleiten.
        </p>
        <p className="mb-1  pb-2">
          Besuchen Sie <a href="https://rawstuff.deals/">www.rawstuff.deals</a>,
          um mehr zu erfahren und sich anzumelden.
        </p>
      </div>
    </div>
  );
}

export default RP_Detail1;